<template>
  <b-carousel-list
    :data="items"
    :items-to-show="computedItemsToShow"
    :has-drag="true"
    :arrow="true"
    :arrow-hover="arrowHover"
    :icon-prev="iconPrev"
    :icon-next="iconNext"
    :icon-size="iconSize"
  >
    <template #item="list">
      <EventCard :event="list" :show-details="true"/>
    </template>
  </b-carousel-list>
</template>

<script>
import { mapState } from 'vuex';
import EventCard from '../../modules/Root/Billboard/event-card';

export default {
  components: {
    EventCard,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    itemsToShow: {
      type: Number,
      default: 5,
    },
    pauseInfo: {
      type: Boolean,
      default: false,
    },
    arrowHover: {
      type: Boolean,
      default: false,
    },
    iconPrev: {
      type: String,
      default: 'menu-left',
    },
    iconNext: {
      type: String,
      default: 'menu-right',
    },
    iconSize: {
      type: String,
      default: 'is-large',
    },
    indicatorInside: {
      type: Boolean,
      default: false,
    },
    indicator: {
      type: Boolean,
      default: false,
    },
    showContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.ui.isMobile,
    }),
    computedItemsToShow() {
      // Si isMobile es true, itemsToShow será 3, de lo contrario será el valor predeterminado (5)
      return this.isMobile ? 1 : this.itemsToShow;
    },
  },
};
</script>