const state = {
  loading: {
    isLoading: false,
    isFullPage: true,
  },
  menuOpen: false,
  isMobile: false,
  notificationsSeen: false,
};

export default state;