<template>
  <div v-if="selectedState" class="header">
    <div class="container is-widescreen">
      <div class="columns">
        <div class="column">
          <b-navbar centered transparent :close-on-click="false">
            <template #brand>
              <b-navbar-item tag="router-link" :to="{ name: 'home' }">
                <img :src="logo" :key="logo" alt="VAT" />
              </b-navbar-item>
            </template>
            <template #start>
              <router-link v-on:click.native="handleNavLinkClick" :to="{ name: 'home' }" class="navbar-item">{{
                translatedText('home')
              }}</router-link>
              <router-link v-on:click.native="handleNavLinkClick" :to="{ name: 'cartelera', query: { page: 1, size: 20 } }" class="navbar-item">{{
                translatedText('showtimes')
              }}</router-link>
              <router-link v-on:click.native="handleNavLinkClick" :to="{ name: 'espectador-frecuente' }" class="navbar-item">{{
                translatedText('frequent_viewer')
              }}</router-link>
              <router-link v-on:click.native="handleNavLinkClick" :to="{ name: 'blog' }" class="navbar-item">{{
                translatedText('blog')
              }}</router-link>
              <router-link v-on:click.native="handleNavLinkClick" :to="{ name: 'teatros' }" class="navbar-item">{{
                translatedText('theaters')
              }}</router-link>
              <b-navbar-item tag="div">
                <b-dropdown class="account-menu" position="is-bottom-left" aria-role="menu" :mobile-modal="true">
                  <template #trigger>
                    <a class="navbar-item" role="button">
                      <span v-if="usuario.nombre" class="has-text-link">{{ translatedText('my_account') }}</span>
                      <span v-else>{{ translatedText('my_login') }}</span>
                      <b-icon :class="{ 'has-text-link': usuario.nombre }" icon="menu-down"></b-icon>
                    </a>
                  </template>

                  <b-dropdown-item v-if="usuario.nombre" aria-role="menuitem">
                    <b-button class="account-options" tag="router-link" :to="{ name: 'user-profile' }" expanded>{{
                      translatedText('my_profile')
                    }}</b-button>
                  </b-dropdown-item>

                  <b-dropdown-item v-if="usuario.nombre">
                    <b-button class="account-options" @click="logOut" expanded>{{ translatedText('logout') }}</b-button>
                  </b-dropdown-item>

                  <b-dropdown-item aria-role="menu-item" :focusable="false" custom paddingless v-if="!usuario.nombre">
                    <!-- Contenido del modal -->
                    <div class="modal-card">
                      <section class="modal-card-body">
                        <!-- Aquí va el contenido del formulario según la opción seleccionada -->
                        <template v-if="selectedOption === 'login'">
                          <LoginForm />
                        </template>
                        <template v-if="selectedOption === 'register'">
                          <RegisterForm />
                        </template>
                        <template v-if="selectedOption === 'forgotPassword'">
                          <ForgotPasswordForm />
                        </template>
                      </section>
                      <footer class="modal-card-foot">
                        <p v-if="selectedOption === 'register' || selectedOption === 'forgotPassword'" class="is-size-6 has-text-weight-bold mb-2">
                          {{ translatedText('already_have_account') }}
                          <a @click="selectedOption = 'login'">{{ translatedText('login_here') }}</a>
                        </p>
                        <p v-if="selectedOption === 'login'" class="is-size-6 has-text-weight-bold mb-2">
                          {{ translatedText('no_account') }}
                          <a @click="selectedOption = 'register'">{{ translatedText('register_here') }}</a>
                        </p>
                        <p v-if="selectedOption === 'login'" class="is-size-6 has-text-weight-bold mb-2">
                          <a @click.stop="selectedOption = 'forgotPassword'" role="button">{{ translatedText('forgot_password') }}</a>
                        </p>
                      </footer>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </b-navbar-item>
              <b-navbar-item tag="div">
                <b-field class="location-menu" ref="locationMenu">
                  <b-select v-model="selectedStateInput" @input="updateSelectedState" placeholder="ESTADOS" rounded>
                    <template v-for="state in statesCatalog">
                      <option v-if="state.estado && state.estatus == 1 && state.siglas != 'ALL'" :key="state.id" :value="state.id">
                        {{ state.estado.toUpperCase() }}
                      </option>
                    </template>
                  </b-select>
                </b-field>
              </b-navbar-item>
            </template>
          </b-navbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import LoginForm from '@/modules/Root/Auth/login.vue';
import RegisterForm from '@/modules/Root/Auth/register.vue';
import ForgotPasswordForm from '@/modules/Root/Auth/forgotPassword.vue';
import Swal from 'sweetalert2';

export default {
  components: {
    LoginForm,
    RegisterForm,
    ForgotPasswordForm,
  },
  data() {
    return {
      selectedStateInput: localStorage.getItem('selectedState') || null,
      selectedOption: 'login',
    };
  },
  computed: {
    ...mapState({
      statesCatalog: (state) => state.statesCatalog.statesCatalog,
      selectedState: (state) => state.statesCatalog.selectedState,
      usuario: (state) => state.auth.usuario,
      menuOpen: (state) => state.ui.menuOpen,
      isMobile: (state) => state.ui.isMobile,
      contenidos: (state) => state.dashboard.contenidos,
    }),
    logo() {
      const contenidoLogo = this.contenidos.find((contenido) => contenido.nombre == 'Logo');
      if (contenidoLogo && contenidoLogo.logo) {
        return contenidoLogo.logo;
      } else {
        return 'https://voyalteatro.mx/v.1.0.0/media/_admin/images/headervat.png';
      }
    },
  },
  mounted() {
    this.loadSelectedStateFromLocalStorage();
    this.loadUserFromLocalStorage();
    this.loadStatesCatalog();
  },
  methods: {
    ...mapActions('statesCatalog', ['fetchEstados']),
    ...mapMutations({
      setDataUi: 'ui/SET_DATA',
    }),
    updateSelectedState() {
      const selectedStateNumber = parseInt(this.selectedStateInput);

      if (!isNaN(selectedStateNumber)) {
        this.$store.commit('statesCatalog/SET_DATA', { key: 'selectedState', data: selectedStateNumber });
        localStorage.setItem('selectedState', selectedStateNumber.toString());
        if (this.$route.path === '/') {
          window.location.reload();
        } else {
          this.$router.push({ path: '/' }).then(() => {
            window.location.reload();
          });
        }
      }
    },
    loadStatesCatalog() {
      this.fetchEstados();
    },
    loadSelectedStateFromLocalStorage() {
      const storedSelectedState = localStorage.getItem('selectedState');
      const parsedSelectedState = storedSelectedState ? parseInt(storedSelectedState) : null;
      this.$store.commit('statesCatalog/SET_DATA', { key: 'selectedState', data: parsedSelectedState });
      this.selectedStateInput = parsedSelectedState;
    },
    loadUserFromLocalStorage() {
      const storedUsuario = localStorage.getItem('usuario');
      const storedToken = localStorage.getItem('token');
      const storedTokenSesion = localStorage.getItem('token_sesion');

      if (storedUsuario) {
        try {
          const usuario = JSON.parse(storedUsuario);
          this.$store.commit('auth/SET_DATA', { key: 'usuario', data: usuario });
        } catch (error) {
          console.error('Error parsing usuario from localStorage:', error);
        }
      }

      if (storedToken) {
        this.$store.commit('auth/SET_DATA', { key: 'token', data: storedToken });
      }

      if (storedTokenSesion) {
        this.$store.commit('auth/SET_DATA', { key: 'token_sesion', data: storedTokenSesion });
      }
    },
    logOut() {
      Swal.fire({
        icon: 'success',
        title: this.translatedText('log_out_title'),
        text: this.translatedText('log_out_text'),
        confirmButtonColor: '#268af7', // Color personalizado del botón de confirmación
      }).then(() => {
        localStorage.removeItem('usuario');
        localStorage.removeItem('token');
        localStorage.removeItem('token_sesion');
        this.$store.commit('auth/SET_DATA', { key: 'usuario', data: {} });
        this.$store.commit('auth/SET_DATA', { key: 'token', data: null });
        this.$store.commit('auth/SET_DATA', { key: 'token_sesion', data: null });
        this.$store.commit('auth/SET_DATA', { key: 'list', data: [] });
        this.$store.commit('auth/SET_DATA', { key: 'options', data: [] });

        // Redireccionar al usuario a la página principal si está en el perfil
        if (this.$route.name === 'user-profile') {
          this.$router.push({ name: 'home' });
        }
      });
    },
    toggleMenu() {
      var elemento = document.querySelector('.account-menu .navbar-item');

      if (this.isMobile) {
        elemento = document.querySelector('.navbar-burger');
      }

      setTimeout(() => {
        if (elemento) {
          window.scrollTo(0, 0);
          elemento.click();

          if (this.isMobile) {
            var elementoMobil = document.querySelector('.account-menu .navbar-item');
            elementoMobil.click();
          }
        } else {
          console.error('El elemento no fue encontrado.');
        }
      }, 500);
    },
    handleNavLinkClick() {
      if (this.isMobile) {
        var elemento = document.querySelector('.navbar-burger');
        setTimeout(() => {
          if (elemento) {
            window.scrollTo(0, 0);
            elemento.click();
          } else {
            console.error('El elemento no fue encontrado.');
          }
        }, 100);
      }
    },
  },
  watch: {
    menuOpen() {
      this.toggleMenu();
    },
  },
};
</script>